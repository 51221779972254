import Dexie, { type Table } from "dexie";
import type { ReactionType } from "~/src/api";

export type ogDataResponse = {
  og: {
    title: string;
    type: string;
    image: string;
    url: string;
    description: string;
  };
  meta: {
    domain: string;
    youtube_id?: string;
    playlist_id?: string;
  };
  cache: {
    expiry: number;
    state: boolean;
    key: string;
  };
};

interface DatabaseReadMarker {
  id: string;
  relatedTitleId: string;
  timestamp: string | undefined;
  read: boolean;
}

interface DatabaseOGData {
  url: string;
  domain: string;
  timestamp: number;
  ogData: ogDataResponse;
}

// this is used to track post stats so they can get updated separately from posts
export interface DatabasePostMeta {
  postId: string;
  userReposted: boolean;
  userReaction: ReactionType | null;
  allReactions: { [key in ReactionType]?: number };
  totalReposts: number | null;
  totalComments: number | null;
  dateUpdated: number;
  isFetching?: boolean;
}

// this is used to track comment stats so they can update/sync separately from comments
export interface DatabaseCommentMeta {
  commentId: string;
  userReaction: ReactionType | null;
  allReactions: { [key in ReactionType]?: number };
  dateUpdated: number;
  isFetching?: boolean;
}

// this adds support for browser saved read markers
// we can optionally save all their markers when they make an account
class NamiDatabase extends Dexie {
  // 'readMarkers' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  readMarkers!: Table<DatabaseReadMarker>;
  ogData!: Table<DatabaseOGData>;

  //meta
  postMeta!: Table<DatabasePostMeta>;
  commentMeta!: Table<DatabaseCommentMeta>;

  private static readonly targetVersion = 4;

  constructor() {
    super("namiDB");
    this.version(NamiDatabase.targetVersion).stores({
      readMarkers: "&id, relatedTitleId, read, timestamp",
      ogData: "&url, timestamp, domain, ogData",
      postMeta:
        "&postId, userReposted, userReaction, allReactions, totalReposts, totalComments, dateUpdated, isFetching",
      commentMeta:
        "&commentId, userReaction, allReactions, dateUpdated, isFetching",
    });
  }

  public override open() {
    if (this.isOpen()) return super.open();

    return Dexie.Promise.resolve()
      .then(() => Dexie.exists(this.name))
      .then((exists) => {
        if (!exists) {
          // no need to check database version since it doesn't exist
          return;
        }

        // Open separate instance of dexie to get current database version
        return new Dexie(this.name).open().then(async (db) => {
          if (db.verno === NamiDatabase.targetVersion) {
            // database up to date
            return db.close();
          }

          console.debug(
            `Database schema out of date, resetting all data. (currentVersion: ${db.verno}, expectedVersion: ${NamiDatabase.targetVersion})`,
          );
          await db.delete();

          // ensure the delete was successful
          const exists = await Dexie.exists(this.name);
          if (exists) {
            throw new Error("Failed to remove mock backend database.");
          }
        });
      })
      .then(() => super.open());
  }
}

export const NamiCacheDatabase = new NamiDatabase();
